.Header {
    background-color: #282c34;
  }

  .Header-Content {
    background-color: #282c34;
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: normal;
  }

  .Header-text {
    text-align: left;
    font-size: calc(10px + 2vmin);
    margin: 0;
    color: rgb(255, 255, 255);
  }

  .Header-button {
    background-color: #272c36;
    color: rgb(255, 255, 255);
    font-size: calc(10px + .5vmin); 
  }

  .Header-button-red {
    background-color: #c40000;
    color: rgb(255, 255, 255);
    font-size: calc(10px + .5vmin); 
  }
  .Header-button-blue {
    background-color: #0055ff;
    color: rgb(255, 255, 255);
    font-size: calc(10px + .5vmin); 
  }
  .Header-button-green {
    background-color: #04ff00;
    color: rgb(255, 255, 255);
    font-size: calc(10px + .5vmin); 
  }

  .Header-button:hover {
    background-color: #0055ff;
    color: rgb(255, 255, 255);
  }

  .Header-navigationBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: rgb(255,255, 255);
  }